import { ChakraProvider as ChakraProviderInner } from '@chakra-ui/provider';
import { extendTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

const getChakraTheme = (theme?: DefaultTheme) => {
    return extendTheme({
        fonts: {
            heading: theme?.fonts?.default,
            body: theme?.fonts?.default,
        },
        ET: theme,
        components: {
            Button: {
                baseStyle: {
                    fontWeight: 'medium',
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: theme?.colors.text,
                },
            },
        },
        zIndices: {
            modal: 2147483010,
            overlay: 2147483005,
        },
        colors: {
            blue: {
                50: '#FFFFF0',
                100: '#FEFCBF',
                200: '#FAF089',
                300: '#F6E05E',
                400: '#ECC94B',
                500: '#FEB001',
                600: '#B7791F',
                700: '#975A16',
                800: '#744210',
                900: '#5F370E',
            },
            originalBlue: {
                50: '#ebf8ff',
                100: '#bee3f8',
                200: '#90cdf4',
                300: '#63b3ed',
                400: '#4299e1',
                500: '#3182ce',
                600: '#2b6cb0',
                700: '#2c5282',
                800: '#2a4365',
                900: '#1A365D',
            },
        },
    });
};

export const ChakraProvider = (props: { children: ReactNode }) => {
    const theme = useTheme();
    return (
        <ChakraProviderInner theme={getChakraTheme(theme)}>{props.children}</ChakraProviderInner>
    );
};
