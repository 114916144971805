import create, { State } from 'zustand';

interface IWidgetSnippetState extends State {
    partnerID: string;
    setPartnerID: (partnerID: string) => void;
}

export const useWidgetSnippetState = create<IWidgetSnippetState>(
    (set): IWidgetSnippetState => ({
        partnerID: '',
        setPartnerID: (partnerID: string) => set({ partnerID }),
    })
);
