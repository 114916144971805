import 'normalize.css';

import { GlobalStyle } from 'components/GlobalStyle';
import { LoaderOverlay } from 'components/LoaderOverlay';
import { ChakraProvider } from 'components/providers/ChakraProvider';
import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from 'themes/default';

import { WidgetSnippet } from '../components/providers/WidgetSnippet';

const { publicRuntimeConfig } = getConfig();

const tagManagerArgs = {
    gtmId: publicRuntimeConfig?.googleTagManagerKey,
    auth: publicRuntimeConfig?.googleTagManagerAuth,
    preview: publicRuntimeConfig?.googleTagManagerPreview,
};

const TAGS = {
    TITLE: 'Eddy AI Assistant',
    DESCRIPTION:
        'Generate sales on autopilot and provide a seamless customer support experience with the Eddy AI Assistant.',
};

function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        /* @ts-ignore */
        <ThemeProvider theme={DefaultTheme}>
            <GlobalStyle />
            <Head>
                <meta name="robots" content="noindex" />
                <meta name="viewport" content="width=device-width, user-scalable=no" />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content={publicRuntimeConfig?.facebookAppId} />
                <title>{TAGS.TITLE}</title>
                <meta key="og:title" property="og:title" content={TAGS.TITLE} />
                <meta key="og:description" property="og:description" content={TAGS.DESCRIPTION} />
                <meta name="description" content={TAGS.DESCRIPTION} />
                <meta
                    key="og:image"
                    property="og:image"
                    content={`${publicRuntimeConfig?.rootUrl}/images/eddyai-assistant.jpg`}
                />
                <meta key="og:url" property="og:url" content={publicRuntimeConfig?.rootUrl} />
            </Head>
            <ChakraProvider>
                <Component {...pageProps} />
            </ChakraProvider>
            <LoaderOverlay />
            <WidgetSnippet />
        </ThemeProvider>
    );
}
// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext: AppContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);

    return { ...appProps };
};

export default MyApp;
