import getConfig from 'next/config';
import Script from 'next/script';
import React, { useEffect } from 'react';

import { useWidgetSnippetState } from '../hooks/UseWidgetSnippetState';

const { publicRuntimeConfig } = getConfig();

export const WidgetSnippet = () => {
    const partnerID = useWidgetSnippetState((state) => state.partnerID);
    if (!partnerID) {
        return null;
    }
    return (
        <>
            <Script
                dangerouslySetInnerHTML={{
                    __html: `window.__ET = { partnerId: '${partnerID}'};`,
                }}
            />
            <Script src={publicRuntimeConfig?.widgetScriptUrl} />
        </>
    );
};
